<template>
  <div class="sm:flex justify-between items-end border-t-2 pr-10 text-main_blue">
    <div class="flex items-center gap-x-2.5 sm:pl-10">
      <img class="h-auto w-24 m-auto sm:m-0" src="/assets/img/logo-BR-groupe.webp" alt="Logo de la société" />
      <div class="hidden sm:block">
        <p class="font-bold">Borne Recharge Service - 18 bis rue Molitor - 75016 Paris</p>
        <p class="font-bold">
          <a href="mailto:info@bornerecharge.fr">info@bornerecharge.fr</a> -
          <a href="tel:0184252670">01 84 25 26 70</a>
        </p>
      </div>
    </div>
    <div class="hidden sm:flex sm:flex-col justify-between items-end h-full">
      <div class="flex items-center">
        <NuxtLink to="https://www.linkedin.com/company/borne-recharge-service/" target="_blank"
          ><i class="fa-brands fa-linkedin text-main_blue text-2xl mr-5"></i
        ></NuxtLink>
        <NuxtLink to="https://www.instagram.com/bornerecharge/" target="_blank"
          ><i class="fa-brands fa-instagram text-main_blue text-2xl mr-5"></i
        ></NuxtLink>
        <NuxtLink to="https://twitter.com/bornerecharge" target="_blank"
          ><i class="fa-brands fa-x-twitter text-main_blue text-2xl mr-5"></i
        ></NuxtLink>
      </div>
      <div class="justify-center items-center gap-2 p-2 flex">
        <NuxtLink to="https://bornerecharge.fr/mentions-legales/" class="text-main_blue text-xs" target="_blank"
          >Mentions légales</NuxtLink
        >
        <p class="text-main_blue text-xs">|</p>
        <NuxtLink to="https://bornerecharge.fr/privacy-policy/" class="text-main_blue text-xs" target="_blank"
          >Politique de confidentialité</NuxtLink
        >
        <p class="text-main_blue text-xs">|</p>
        <NuxtLink
          to="https://bornerecharge.fr/conditions-generales-dutilisation-vente/"
          class="text-main_blue text-xs"
          target="_blank"
          >Conditions générales</NuxtLink
        >
        <p class="text-main_blue text-xs">|</p>
        <NuxtLink to="https://bornerecharge.fr/conditions-de-parrainage/" class="text-main_blue text-xs" target="_blank"
          >Conditions de parrainage</NuxtLink
        >
        <p class="text-main_blue text-xs">|</p>
        <NuxtLink
          to="https://bornerecharge.fr/comment-une-borne-de-recharge/"
          class="text-main_blue text-xs"
          target="_blank"
          >FAQ</NuxtLink
        >
      </div>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
