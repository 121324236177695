<script lang="ts" setup>
import { reactive } from 'vue';
import { getAlert } from '~/utils/serverClient';
import { useCurrentAuthenticatedUser } from '~/composables/user.store';
import { useAllStores } from '~/composables/all-stores.store';

const form = reactive({
  pending: false,
});

const config = useRuntimeConfig();
const { resetAllStores } = useAllStores();
const { definedCurrentAuthenticatedUser } = storeToRefs(useCurrentAuthenticatedUser());

const user = definedCurrentAuthenticatedUser;

const alertData = await getAlert(config.public.APP_TOKEN);
const alert = alertData.message.length > 0 ? alertData.message : undefined;

async function onLogoutClick() {
  try {
    form.pending = true;

    resetAllStores({ redirectToLoginPage: true });
  } catch (error) {
    console.error(error);
  } finally {
    form.pending = false;
  }
}

const menu = () => {
  const button = document.getElementById('menu');
  if (button) {
    button.classList.toggle('hidden');
  }
};
</script>

<template>
  <header>
    <div>
      <div class="shadow-lg flex justify-between p-5 items-center max-h-24">
        <div class="md:hidden" @click="menu">
          <i class="fa-solid fa-bars text-main_blue text-2xl"></i>
        </div>
        <NuxtLink to="/mon-tableau-de-bord" class="flex items-center gap-x-2.5">
          <img class="h-auto w-24" src="/assets/img/logo-BR-groupe.webp" alt="Logo de la société" />
          <div class="hidden sm:block">
            <p class="text-main_blue font-bold">Vous branchez, vous roulez,</p>
            <p class="text-second_blue font-bold">le reste on s'en charge!</p>
          </div>
        </NuxtLink>
        <div class="items-center gap-x-3.5 hidden md:flex">
          <p class="text-main_blue font-bold">{{ user.given_name }} {{ user.family_name }}</p>
          <button
            class="border-2 border-main_blue rounded-md text-main_blue px-3 py-2 hover:bg-main_blue hover:text-white"
            :disabled="form.pending"
            @click="onLogoutClick"
          >
            Déconnexion
          </button>
        </div>
        <div class="md:hidden">
          <i class="fa-solid fa-right-from-bracket text-main_blue text-2xl" @click="onLogoutClick"></i>
        </div>
      </div>
      <MenuComponent id="menu" class="hidden md:block" />
      <div class="bg-red-300 p-2 text-center border-red-600 border-2" :class="{ hidden: alert === undefined }">
        {{ alert }}
      </div>
    </div>
  </header>
</template>
